<template>
  <div>
    <v-container>
      <div class="d-flex align-center my-4">
        <v-btn
            fab
            x-small
            elevation="0"
            @click="$router.go(-1)"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <div class="text-h6 px-2">Detalle de compra</div>
      </div>
      <div v-for="(item,index) in resume" :key="index">
        <p class="font-weight-light text-uppercase mb-0">
          {{ item.label }}
        </p>
        <p class="font-weight-black">
          {{ item.value }}
        </p>
      </div>
      <div>
        <p class="font-weight-light text-uppercase">
          Pedido
        </p>

        <v-row v-for="(item,index) in order.items" :key="index" class="font-weight-black">
          <v-col cols="7" class="text-truncate py-1">{{ item.name }}</v-col>   <v-col cols="2" class="py-1">x {{item.quantity}}</v-col>  <v-col cols="3" class="text-right py-1">{{(item.item_related_id ? 0 :  item.quantity * item.unit_price) | money}}</v-col>
        </v-row>
        <div class="mt-5 d-flex flex-row justify-space-between">
          <div>Saldo Disponible</div>   <div>{{order.available_discount | money}}</div>
        </div>
        <div class="font-weight-black  d-flex flex-row justify-space-between">
          <div> Total:</div>    <div>{{totalPrice | money}}</div>
        </div>


      </div>
      <div>
        <v-btn depressed x-large block tile class="orange white--text mt-6" @click="goToPayment" :loading="sendingPayment">
          <v-icon small>mdi-star-three-points-outline</v-icon> Confirmar compra
        </v-btn>
      </div>
    </v-container>
  </div>
</template>

<script>
import {Restaurant} from "@/services/api";

export default {
  name: "Details",
  data(){
    return{
      sendingPayment: false,
    }
  },
  computed:{
    resume(){
      const order = this.$store.state.order
      return[
        {
          label: 'Movil',
          value: order.phone_number
        },
      ]
    },
    order(){
      return this.$store.state.order
    },
    totalPrice(){
      const items = this.$store.state.order ? this.$store.state.order.items : [];
      let price = 0;
      items.forEach(item =>{
        if(!item.item_related_id) {
          price += item.quantity * item.unit_price
        }
      })
      price -= (this.$store.state.order.available_discount)
      return price
    },
    user(){
      return this.$store.state.user || {}
    },


  },
  mounted() {
    this.$store.commit('setLoading', false)
  },
  methods:{
    goToPayment(){
      this.sendingPayment = true
      if(this.totalPrice > 0){
        return this.$router.push({name:'MobileCartPayment'})
      }else{
        Restaurant(this.user.token_type, this.user.access_token).PayOrder('none').then(
            res => {
              this.$router.push({ path: '/restaurant/confirm', query: { id: res.receipt } })
            }
        );
      }
    },
  }
}
</script>

<style scoped>

</style>